.pp-banner img {
    max-width: 100%;
}

.pp-label {
    width: 320px;
}

.pp-label {
    width: 100%;
    /* background: #fff; */
    /* margin: 20px auto; */
    /* box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); */
    /* position: absolute; */
    left: 0;
    right: 0;
    bottom: 0px;
    font-weight: bold;
    margin: 50px auto;
    margin-bottom: 0;
    font-size: 32px;
    text-align: center;
    color: #000;
    padding: 8px;
}

.pp-banner {
    position: relative;
}
.pp-banner img {
    height: 250px;
    width: 100%;
    object-position: center;
    object-fit: cover;
    max-width: 1240px;
    margin: 0 auto;
    position: relative;
    display: block;
}

.support .staticPageContent {
    padding: 15px;
}
.staticPageContent {
    min-height: 70vh;
}
@media (max-width: 500px) {
    .support .supportFaq p:last-child {
        margin-bottom: 0;
    }
    .support .MuiTab-wrapper {
        font-size: 13px;
    }
}
