.dashboard {
    background: var(--backgroundColor);
    padding-top: 30px;
    padding-bottom: 30px;
}

.sideNav {
    padding: 10px 0;
    text-align: left;
}

.sideNav h6 {
    font-size: 13px;
    color: #b2b2b2;
    padding-left: 10px;
    font-weight: 400;
    text-transform: uppercase;
}

.sideNav h5 {
    font-size: 15px;
    font-weight: 600;
    color: var(--primColor);
    text-align: left;
    padding-top: 15px;
}

.sideNav ul {
    padding-left: 0;
    margin: 0;
}

.sideNav ul a {
    text-transform: uppercase;
    padding: 3px 0;
}

.sideNav ul a,
.sideNav ul .MuiListItem-button {
    width: 100%;
    height: 100%;
    display: block;
    color: #b2b2b2 !important;
    font-size: 16px;
    font-weight: 700;
    display: flex;
    justify-content: flex-start;
}

.sideNav ul a span,
.sideNav ul .MuiListItem-button span {
    padding-right: 10px;
}

.sideNav ul .MuiCollapse-wrapperInner {
    padding-left: 35px;
}

.sideNav ul a:hover {
    text-decoration: none;
    color: var(--primColor) !important;
}

.sideNav ul a.active,
.sideNav ul .activeDropdown {
    color: var(--primColor) !important;
}

.dashboard .productCardGrid {
    margin-right: 10px;
}

.dashboard .dashboardLt .gridListToggle button {
    color: var(--secColor);
    width: 50%;
}

.dashboard .dashboardLt .gridListToggle button.active {
    color: var(--primColor);
    background: #fff;
}

.dashboard .dashboardLt .gridListToggle i {
    padding-right: 10px;
}

.dashboard .myAuctions .customSelect {
    margin-bottom: 0;
}

.dashTitle {
    font-size: 25px;
    font-weight: 700;
    color: #353535;
    text-align: left;
    text-transform: uppercase;
    margin-bottom: 15px;
    width: 100%;
}

.dashTitle span {
    font-size: 14px;
    font-weight: 600;
    color: #999999;
    padding-left: 10px;
    text-transform: initial;
}

.myAccount .accountCnt {
    background: #fff;
    border-radius: 5px;
    padding: 30px;
    width: 100%;
}

.myAccount .accChild h4 {
    text-align: left;
    font-size: 18px;
    font-weight: 600;
    margin: 25px 0;
}

.myAccount .primButton {
    max-width: 200px;
    margin: 20px auto;
}

.mySavedCards .cardActBtn button:first-child {
    margin-right: 10px;
}

.mySavedCards .cardHead {
    margin-bottom: 20px;
}

.mySavedCards .cardHead button {
    color: var(--primColor);
    border-color: var(--primColor);
}

.mySavedCards button span.material-icons {
    padding-right: 10px;
    font-size: 20px;
}

.mySavedCards .modal-dialog.modal-lg {
    max-width: 500px !important;
}

.mySavedCards .modal-dialog.modal-lg .primButton {
    width: 200px;
    margin: 0 auto;
}

.mySavedCards ::-webkit-datetime-edit {
    color: transparent;
}

.mySavedCards :focus::-webkit-datetime-edit {
    color: #000;
}

.auctionStatus {
    display: none;
}

.auctionStatus .MuiListItem-root {
    padding: 0;
    width: max-content;
    margin-bottom: 25px;
    border-radius: 50px;
}

.auctionStatus .MuiListItem-root a {
    padding: 10px 25px;
    background: #fff;
    border: 1px solid #d8d8d8;
    border-radius: 50px;
    color: #000;
    height: 45px;
}

.auctionStatus .MuiListItem-root a:focus,
.auctionStatus .MuiListItem-root a:hover {
    text-decoration: none;
}

.auctionStatus .MuiListItem-root:not(:last-child) {
    margin-right: 16px;
}

.auctionStatus .MuiListItem-root a.active {
    color: var(--primColor);
    background: rgb(174 34 7/8%);
    border-color: rgb(174 34 7/51%);
}

.dashboard .searchResults {
    margin-top: 15px;
}

.dashboard .customContainer {
    padding-left: 0px;
    padding-right: 0px;
}

.dashboard .pagination-wrapper {
    padding: 0 15px;
}

.respSort .sideNav ul .MuiListItem-gutters {
    padding-right: 0px !important;
}

@media (max-width: 1024px) {
    .respSort {
        display: none;
    }

    .auctionStatus {
        display: flex;
    }

    .dashTitle {
        font-size: 18px;
    }

    .dashTitle span {
        font-size: 14px;
        display: inline-block;
    }

    .sideNav h6 {
        text-align: left;
    }

    .sideNav ul a,
    .sideNav ul .MuiListItem-button {
        justify-content: flex-start;
        font-size: 14px;
    }

    .sideNav {
        padding-top: 30px;
    }
}

@media (max-width: 545px) {
    .auctionStatus .MuiListItem-root a {
        padding: 10px 19px;
        height: 40px;
        font-size: 15px;
        line-height: 1.2;
    }

    .dtWrapper {
        width: 100%;
    }

    .dashTitle span {
        /* padding-left: 0; */
    }
}

@media (max-width: 470px) {
    .auctionStatus .MuiListItem-root a {
        padding: 10px 15px;
        height: 40px;
        font-size: 13px;
        line-height: 1.4;
    }

    .dashTitle {
        font-size: 16px;
    }

    .dashTitle span {
        font-size: 12px;
    }
}

@media (max-width: 420px) {
    .auctionStatus .MuiListItem-root:not(:last-child) {
        margin-right: 5px;
    }
}

@media (max-width: 385px) {
    .auctionStatus .MuiListItem-root a {
        padding: 10px 19px;
        font-size: 13px;
    }

    .auctionStatus .MuiListItem-root:not(:last-child) {
        margin-right: 5px;
    }

    .auctionStatus {
        flex-wrap: wrap;
    }
}

@media (max-width: 340px) {
    .auctionStatus .MuiListItem-root a {
        font-size: 12px;
    }

    .auctionStatus .MuiListItem-root {
        margin-bottom: 15px;
    }
}
