.siteMapcontainer .sitemap_section:not(:last-child) {
    margin-bottom: 30px;
}

.siteMapcontainer {
    background-color: white;
    margin: 25px auto;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    width: 100%;
    border-radius: 5px;
    padding: 20px 35px;
    text-align: left;
}

.siteMapcontainer .heading {
    margin-top: 0px;
    margin-bottom: 20px;
    text-align: center;
    color: #666;
    font-size: 28px;
    font-weight: 700;
}

.siteMapcontainer .sub-heading {
    font-size: 18px;
    color: var(--primColor);
    font-weight: 500;
    text-align: center;
    margin: 25px auto 35px;
    text-transform: uppercase;
}

.siteMapcontainer .section-heading {
    font-size: 20px;
    margin-bottom: 5px;
    color: #4b4b4b;
    text-align: left;
    font-weight: 600;
}

.siteMapcontainer .sitemap_section a {
    color: #777 !important;
}

.siteMapcontainer .sitemap_section a:hover {
    color: var(--primColor) !important;
}

@media (max-width: 500px) {
    .siteMapcontainer {
        padding: 20px 15px;
    }
    .siteMapcontainer .sitemap_section a {
        padding-left: 20px;
    }
    .siteMapcontainer .sitemap_section:not(:last-child) {
        margin-bottom: 20px;
    }
    .siteMapcontainer .section-heading {
        font-size: 18px;
    }
}
