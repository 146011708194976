.biddingCardCnt {
    position: sticky;
    top: 30px;
    z-index: 1;
}
.bidMessage {
    font-size: 14px;
    margin: 5px;
}
.biddingCard {
    text-align: center;
    padding: 10px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);
    margin-top: 5px;
    border-radius: 8px;
    padding-bottom: 12px;
    max-width: 400px;
    background: #fff;
    position: relative;
}
.modal-footer label {
    color: #fe0100;
}
.biddingCard .thirdPartyIcon {
    display: block;
    position: absolute;
    right: 10px;
    top: -30px;
}

.biddingCard h3 {
    color: var(--primColor);
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.6px;
    padding-bottom: 10px;
    text-align: center;
}

.biddingCard .bidTimeCnt {
    text-align: center;
    background: #f2f2f2;
    padding: 22px 15px;
    padding-bottom: 10px;
    text-align: center;
}

.biddingCard .bidTimeCnt label {
    color: #b2b2b2;
    font-size: 16px;
    margin: 0;
    padding-bottom: 10px;
}

.biddingCard .bidTimeCnt h5 {
    font-size: 16px;
    font-weight: 500;
    color: var(--primColor);
}

.biddingCard .bidTimeCnt .bidTimeCntLt,
.biddingCard .bidTimeCnt .bidTimeCntRt {
    width: 48%;
    padding-bottom: 10px;
}

.biddingCard .bidTimeCnt .bidTimeCntRt h5 {
    color: var(--primColor);
}

.biddingCard .bidTimeCnt .bidTimeCntLt h6,
.biddingCard .bidTimeCnt .bidTimeCntRt h6 {
    font-size: 12px;
    margin: 0;
    color: #666;
}

.biddingCard .viewBids {
    font-size: 14px;
    color: var(--primColor);
    text-transform: initial;
    font-weight: 600;
}

.biddingCard .bidInputCnt {
    display: flex;
    justify-content: center;
    height: 70px;
    padding: 0 15px;
    background: #f2f2f2;
}

.biddingCard .bidInputCnt .bcBidInput {
    display: flex;
    position: relative;
    justify-content: center;
}

.biddingCard .bidInputCnt .bcBidInput label {
    color: #b2b2b2;
    font-size: 16px;
    position: absolute;
    top: 5px;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 999;
    width: 120px;
    font-weight: 500;
    text-align: center;
}

.biddingCard .bidInputCnt .bcBidInput input {
    padding: 20px 0px 10px;
    text-align: center;
    border: 1px solid #e8e6e6;
    color: var(--primColor);
    font-weight: 600;
    font-size: 22px;
    width: 100%;
}

.biddingCard .bidInputCnt .bcBidInput input::-webkit-outer-spin-button,
.biddingCard .bidInputCnt .bcBidInput input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.biddingCard .bidInputCnt .bcBidInput input[type='number'] {
    -moz-appearance: textfield;
}

.biddingCard .bidInputCnt button {
    background-color: var(--primColor);
    color: #fff;
    border-radius: 0;
}

.biddingCard .bidInputCnt button:hover {
    background-color: var(--primColor);
}

.biddingCard .bidInputCnt input:focus {
    outline: 0;
    border: 1px solid var(--primColor);
}

.biddingCard .nextBidValue {
    padding: 15px;
    margin: 0;
    font-size: 12px;
    color: #666;
    background: #f2f2f2;
}

.biddingCard .bcACtBtn {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    align-items: center;
    margin: 10px 0;
}

.biddingCard .autoBid {
    text-transform: initial;
    color: var(--primColor);
    border-color: var(--primColor);
    height: 45px;
}

.margin-5 {
    margin: 5px;
}

.red {
    color: red;
}

.primeColor {
    color: var(--primColor);
}

.biddingCard .contactSeller,
.biddingCard .primButton {
    min-width: max-content;
}

.bcActBtn .tertButton button {
    background-color: #229b36;
    color: #ffffff;
}

@media (max-width: 991px) {
    .biddingCardCnt {
        position: relative;
        margin-bottom: 30px;
    }

    .biddingCardCnt .biddingCard {
        max-width: initial;
    }

    .biddingCard .bidInputCnt .bcBidInput {
        width: 100%;
    }
}

@media (max-width: 500px) {
    .biddingCard .bcActBtn > .col {
        width: 100%;
        flex-basis: auto;
    }
    .biddingCard .bidTimeCnt h5 {
        font-size: 14px;
    }
    .primeColor {
        font-size: 14px;
    }

    .biddingCard .bcActBtn .contactSeller {
        width: 100%;
    }
    .biddingCard .bcActBtn .contactSeller .MuiButton-label {
        flex-wrap: nowrap;
        justify-content: center;
    }
    .biddingCard .bcActBtn .contactSeller .MuiButton-label > span {
        width: max-content;
    }

    .biddingCard .bcActBtn .contactSeller .MuiButton-label > span.material-icons {
        font-size: 24px;
        padding-right: 5px;
    }
    .biddingCard .bcActBtn .primButton {
        margin: 10px 0;
    }
    .biddingCard .autoBid {
        width: 100%;
    }
}
