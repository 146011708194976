.loader {
    background: #fff;
    padding: 60px;
    margin: 100px 0px;
    min-height: 60vh;
}
.loader h3.loaderText {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 2px;
    color: var(--primColor);
    margin: 0;
    margin-left: 15px;
}
