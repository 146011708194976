.searchNav {
    background: #ffffff;
    border: 1px solid #fffbfb;
    padding: 5px 0px;
    box-shadow: 0 1px 3px 0 rgb(214, 214, 214);
    position: relative;
}

.searchNav ul {
    margin-bottom: 0;
    /* margin-left: auto; */
    width: max-content;
}

.searchNav ul .MuiListItem-root {
    border-right: 1px solid #d4d4d4;
    padding: 0;
    width: max-content;
    color: #000 !important;
    text-align: right;
    width: 100%;
    min-width: max-content;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    padding: 10px 15px;
    letter-spacing: 0.5px;
}

.searchNav ul .MuiButtonBase-root.MuiListItem-root:hover {
    color: var(--primColor);
}

.searchNav .searchBox {
    width: 320px;
}

.searchNav .searchBox .customInput {
    margin-bottom: 0;
    margin-left: 15px;
}

.searchNav .MuiListItem-root:first-child {
    font-weight: 700;
}

.searchNav .searchBox .customInput .MuiOutlinedInput-root {
    padding-right: 0;
}

.searchNav .searchBox .customInput .MuiButtonBase-root {
    min-width: 40px;
}

.searchNav .searchBox .customInput .MuiButton-root {
    color: var(--primColor) !important;
}

.categoryMenu .MuiMenuItem-root {
    padding: 15px;
}

.conditionsMenu {
    max-width: 450px;
}

.conditionsMenu .MuiListItem-root {
    flex-wrap: wrap;
}

.conditionsMenu h3 {
    font-weight: 500;
    font-size: 16px;
    color: var(--primColor);
    width: 100%;
    margin-bottom: 3px;
    margin-top: 3px;
}

.conditionsMenu h5 {
    font-size: 13px;
    margin-bottom: 10px;
    margin-top: 5px;
    color: #666;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
}

.customDropdown .MuiPaper-root.MuiMenu-paper.MuiPopover-paper {
    top: 125px !important;
}

.categoryMenu img {
    width: 35px;
    height: 35px;
    object-fit: contain;
    margin-right: 15px;
}

.categoryMenu h5 {
    font-size: 16px;
    color: #666;
    margin: 0;
}

@media (max-width: 1024px) {
    .searchNav {
        padding: 5px 0;
    }
    .searchNav ul {
        width: 100%;
        padding-left: 0;
    }

    .searchNav ul .MuiListItem-root {
        font-size: 13px;
    }
}

@media (max-width: 991px) {
    .searchNav {
        display: none;
    }
}
