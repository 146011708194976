.pp-uimg {
    position: relative;
    width: 92px;
    display: inline-block;
    margin-right: 20px;
    vertical-align: top;
}

.img-del {
    padding: 2px;
    position: absolute;
    width: 25px;
    height: 25px;
    background: #fff;
    border-radius: 50%;
    right: -30px;
    top: -12px;
    cursor: pointer;
    text-align: center;
}

.pro-img {
    height: 200px;
    width: 200px;
    object-fit: cover;
    object-position: center;
    margin-right: 20px;
}
