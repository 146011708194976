:root {
    --fontFamily: 'Roboto';
    --primColor: #fe0100;
    --secColor: #000;
    --tertColor: #fff;
    --accentColor: #b2b2b2;
    --backgroundColor: rgb(241, 243, 246);
}

.primButton button,
.secButton button {
    height: 50px;
}
.lineItemBidAct .primButton button,
.lineItemBidAct .secButton button {
    height: 40px;
}
.primButton.small button,
.secButton.small button {
    height: 40px;
    min-width: max-content;
}

.primButton.large button,
.secButton.large button {
    height: 60px;
    padding: 3px;
}

::-moz-selection {
    /* Code for Firefox */
    color: #fff;
    background: rgb(25, 157, 229, 15);
}

::selection {
    color: #fff;
    background: rgb(25, 157, 229, 15);
}

@media (max-width: 500px) {
    .primButton button,
    .secButton button,
    .primButton.large button,
    .secButton.large button,
    .primButton.small button,
    .secButton.small button {
        height: 45px;
        font-size: 13px;
    }
}
