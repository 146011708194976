body {
    margin: 0;
    font-family: var(--fontFamily), -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #fff;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.cursorPointer {
    cursor: pointer;
}

h1 {
    font-size: 45px;
}

h2 {
    font-size: 28px;
}

h4 {
    font-size: 18px;
}

a {
    color: var(--primColor) !important;
    font-weight: 500;
    cursor: pointer;
}

.customContainer {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    padding-left: 30px;
    padding-right: 30px;
}

div:focus,
button:focus {
    outline: 0;
}

.MuiButton-label {
    font-family: var(--fontFamily), 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.MuiBadge-badge {
    background-color: var(--primColor) !important;
}

.MuiCircularProgress-colorPrimary {
    color: var(--primColor) !important;
}

.MuiMenu-list option {
    padding: 5px 15px;
    cursor: pointer;
}

.customInput,
.customSelect,
.MuiFormControl-root.customDatepicker {
    margin-bottom: 30px;
}

.MuiFormControl-root.customDatepicker {
    width: 100%;
}

.customSelect .MuiFormControl-root {
    width: 100%;
}

.customInput .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutlin,
.customSelect .MuiSelect-root.MuiSelect-select.Mui-focused {
    border-color: var(--secColor);
}

.customInput .MuiFormLabel-root.Mui-focused,
.customSelect .MuiFormLabel-root.Mui-focused {
    color: var(--secColor);
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: var(--secColor) !important;
    border-width: 1px !important;
}

.customInput .MuiFormControl-root.MuiTextField-root,
.customSelect .MuiFormControl-root.MuiTextField-root {
    width: 100%;
    margin: 0;
}

.MuiInputBase-root,
.MuiTypography-body1 {
    font-family: var(--fontFamily) !important;
}

.cursorDecoy {
    cursor: pointer;
}

.customModal {
    z-index: 1500 !important;
}

.search .searchLt,
.dashboard .dashboardLt {
    max-width: 270px;
    width: 100%;
    margin-right: 25px;
}

.search .searchRt,
.dashboard .dashboardRt {
    width: -webkit-fill-available;
    max-width: calc(100% - 240px);
}

.dashboard .dashboardRt {
    max-width: 100%;
    width: calc(100% - 270px);
}

.search .searchResults.Grid,
.dashboard .searchResults.Grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 15px;
}

.toggleRespDrawer.MuiButton-root {
    display: none;
    font-weight: 600;
    text-transform: initial;
    border-color: var(--primColor);
    color: var(--primColor);
    margin-bottom: 20px;
    float: left;
}

.toggleRespDrawer.MuiButton-root .material-icons {
    padding-right: 5px;
}

.myAuctions .gridListToggle .MuiButtonBase-root.MuiButton-root,
.search .searchMiscFilters .MuiButtonBase-root.MuiButton-root,
.dashboard .searchMiscFilters .MuiButtonBase-root.MuiButton-root {
    min-width: max-content;
    color: #8e8e8e;
}

.search .searchMiscFilters .MuiInputBase-root,
.dashboard .searchMiscFilters .MuiInputBase-root {
    height: 40px;
}

.myAuctions .gridListToggle .MuiButtonBase-root.MuiButton-root.active,
.search .searchMiscFilters .MuiButtonBase-root.MuiButton-root.active,
.dashboard .searchMiscFilters .MuiButtonBase-root.MuiButton-root.active {
    color: var(--primColor);
}

.search .searchMiscFilters .rpe .customSelect,
.dashboard .searchMiscFilters .rpe .customSelect {
    margin-bottom: 0;
    min-width: 60px;
    margin-left: 0;
    margin-right: 10px;
}

.dashboard .searchMiscFilters .sort .customSelect,
.dashboard .searchMiscFilters .sort .customSelect {
    margin-bottom: 0;
    min-width: 150px;
    margin-left: 0;
}

.search .searchMiscFilters .rpe > label,
.search .searchMiscFilters .sort > label {
    margin: 0 10px;
    color: #b2b2b2;
    font-size: 15px;
}

.search .searchMiscFilters .customSelect {
    margin-bottom: 0;
}

.moveBack {
    color: #a6adb4;
}

.moveBack .material-icons {
    color: #979797;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background: #dfdfdf;
    margin-right: 5px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: #666;
}

.customBreadcrumbs ol {
    width: max-content;
}

.customBreadcrumbs a {
    color: #797979;
    text-transform: capitalize;
}

.customBreadcrumbs p {
    text-transform: capitalize;
}

.np-pro-box {
    min-height: 20px;
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    background-color: #fff;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.np-pro-box h5 {
    margin-bottom: 0;
}

.backBtn .MuiButton-label {
    color: var(--primColor);
    font-weight: 600;
    text-transform: initial;
    font-size: 15px;
}

.backBtn .MuiButton-label .material-icons {
    font-size: 21px;
    padding-right: 5px;
}

.contactSeller .MuiButton-label {
    flex-wrap: wrap;
}

.contactSeller .MuiButton-label span {
    font-size: 14px;
    color: var(--primColor);
    width: 100%;
}

.contactSeller .MuiButton-label .material-icons {
    font-size: 40px;
    color: var(--primColor);
    margin-bottom: 2px;
}

.MuiTooltip-popper .MuiTooltip-tooltip {
    font-size: 14px;
    padding: 10px;
    line-height: 22px;
}

.customTabs .MuiAppBar-root.MuiAppBar-colorPrimary {
    padding: 0;
    background-color: #e5e5e5;
    box-shadow: none;
}

.customTabs .MuiAppBar-root .MuiButtonBase-root.MuiTab-root {
    color: #333;
    border-radius: 5px;
    text-transform: initial;
}

.customTabs .MuiAppBar-root .MuiButtonBase-root.MuiTab-root.Mui-selected {
    background: #fff;
    color: #333;
}

.customTabs .MuiAppBar-root .MuiTabs-indicator {
    background-color: transparent;
}

.customTabs .MuiBox-root {
    background: #fff;
    padding-left: 10px;
    padding-right: 10px;
}

.MuiSelect-selectMenu {
    text-align: left;
}

.tpTag {
    background: var(--primColor);
    width: 160px;
    border-radius: 10px;
    padding: 5px;
    color: #fff;
}

.tpTag .tpTagInner {
    background: #0ea8a5;
    border: 2px solid #fff;
    border-radius: 10px;
    padding: 5px 15px;
}

.tpTag .tpTagInner > span {
    width: 100%;
    display: block;
    text-align: right;
    font-size: 13px;
    font-weight: 600;
}

.tpTag .tpTagInner h6 {
    margin: 0;
    font-size: 14px;
    font-weight: 700;
}

.tpTag .tpTagInner h6 > span {
    font-size: 18px;
    font-weight: 700;
}

.biddingCard .tpTag {
    position: absolute;
    top: -20px;
    right: -20px;
    transform: rotate(25deg);
}

.staticContent {
    padding: 50px 100px;
    text-align: left;
}

.staticContent .table td,
.staticContent .table th {
    border-top: none;
}

@media (max-width: 1350px) {
    .biddingCard .tpTag {
        margin: 0 auto 15px;
        position: static;
        transform: none;
    }
}

@media (max-width: 1024px) {
    .toggleRespDrawer.MuiButton-root {
        display: inline-flex;
    }

    .searchCnt {
        flex-wrap: wrap;
    }

    .search .searchRt,
    .dashboard .dashboardRt {
        max-width: 100%;
    }
}

@media (max-width: 991px) {
    .biddingCard .tpTag {
        position: absolute;
        top: -20px;
        right: -20px;
        transform: rotate(25deg);
    }
}

@media (max-width: 767px) {
    .search .searchResults.Grid,
    .dashboard .searchResults.Grid {
        grid-template-columns: 1fr 1fr;
    }

    .gridListToggle {
        display: none;
    }
}

@media (max-width: 545px) {
    .dashboard .dashboardRt {
        width: 100%;
    }

    .search .searchResults.Grid,
    .dashboard .searchResults.Grid {
        grid-template-columns: 1fr;
    }

    .searchMiscFilters {
        margin: 15px 0;
        justify-content: flex-start !important;
    }

    .searchMiscFilters .rpe,
    .searchMiscFilters .sort {
        flex-wrap: wrap;
        width: 50%;
    }

    .search .searchMiscFilters .rpe > label,
    .search .searchMiscFilters .sort > label {
        width: 100%;
        text-align: left;
        margin: 0;
        margin-bottom: 5px;
    }

    .search .searchMiscFilters .rpe .customSelect,
    .search .searchMiscFilters .sort .customSelect,
    .dashboard .searchMiscFilters .rpe .customSelect,
    .dashboard .searchMiscFilters .sort .customSelect {
        width: 100%;
    }
}

@media (max-width: 445px) {
    .customContainer {
        padding-left: 15px;
        padding-right: 15px;
    }

    .customInput,
    .customSelect,
    .MuiFormControl-root.customDatepicker {
        margin-bottom: 20px;
    }

    .biddingCard .tpTag {
        margin: 0 auto 15px;
        position: static;
        transform: none;
    }
}
