.plInfo {
    display: flex;
    justify-content: flex-end;
    position: relative;
    margin-bottom: 60px;
}

.plInfo .plInfoLt::before {
    background: url(../../assets/images/ransomware.png) no-repeat;
    background-position: 0% top;
    content: '';
    position: absolute;
    width: 100%;
    height: 700px;
    background-size: contain;
    top: 0;
    left: 0;
    z-index: 1;
}

.plInfo .plInfoLt::after {
    position: absolute;
    content: '';
    background: var(--primColor);
    width: 50%;
    height: 250px;
    bottom: -30px;
    left: 0;
    z-index: 0;
}

.plInfo .plInfoRt {
    background: #fff;
    color: #666;
    box-shadow: 10px 9px 11px -9px #dedede;
    border: none;
    border-radius: 0;
    padding: 45px;
    text-align: left;
    max-width: 625px;
    width: 100%;
    z-index: 10;
    margin-top: 10%;
    margin-right: 5%;
}

.plInfo .plInfoRt .viewPriceLock {
    margin: 45px auto;
    display: block;
    text-align: center;
    font-weight: 700;
}

.priceLock .plAction img {
    z-index: 11;
    margin-top: -4%;
    margin-left: 3%;
    width: 700px;
    height: 500px;
    object-fit: cover;
    object-position: top;
    box-shadow: 0px 0px 14px #949494c4;
    border: 10px solid #fff;
    border-radius: 10px;
    margin-bottom: 20px;
}

@media (max-width: 1024px) {
    .plInfo .plInfoLt::before {
        height: 500px;
    }
}

@media (max-width: 991px) {
    .plInfo .plInfoLt::before {
        height: 40%;
        background-position: 50% top;
        width: 100%;
        background-size: contain;
        left: 50%;
        transform: translateX(-50%);
    }
    .plInfo .plInfoLt::after {
        width: 100%;
        height: 10px;
        bottom: -10px;
    }
    .plInfo .plInfoRt .viewPriceLock {
        margin-bottom: 0;
    }
    .plAction .primButton {
        margin: 20px auto;
        width: 100%;
    }
    .plAction .primButton button {
        width: max-content;
    }
    .plInfo .plInfoRt {
        max-width: initial;
        margin-right: 0;
        margin-top: 400px;
    }
    .priceLock .plAction img {
        margin: 0 auto;
    }
}

@media (max-width: 767px) {
    .priceLock .plAction img {
        width: 100%;
        height: 350px;
    }
}

@media (max-width: 500px) {
    .priceLock .plAction img {
        width: 100%;
        height: 250px;
    }
    .plInfo .plInfoRt {
        margin-top: 300px;
    }
    .plInfo .plInfoLt::before {
        height: 30%;
    }
}

@media (max-width: 445px) {
    .plInfo .plInfoRt {
        padding: 30px 20px;
    }
    .plInfo .plInfoRt h3 {
        font-size: 18px;
    }
    .plInfo .plInfoRt p {
        font-size: 14px;
        margin-bottom: 5px;
    }
    .plInfo .plInfoRt .viewPriceLock {
        margin-top: 20px;
    }
    .plInfo .plInfoLt::before {
        height: 33%;
    }
}

@media (max-width: 380px) {
    .plInfo .plInfoRt {
        margin-top: 280px;
    }
}

@media (max-width: 345px) {
    .plInfo .plInfoLt::before {
        height: 30%;
    }
    .plInfo .plInfoRt {
        margin-top: 260px;
    }
}
