.banner {
    height: auto;
    max-width: 100%;
    display: flex;
    /* background: #fff; */
    margin-bottom: 30px;
    position: relative;
}

.banner::before {
    position: absolute;
    content: '';
    background: #0000009e;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.banner::after {
    position: absolute;
    content: 'Simply add your fully customizable banner';
    color: #fff;
    top: 50%;
    left: 25%;
    /* transform: rotate(-30deg); */
    font-size: 24px;
    font-weight: 700;
}

.banner img {
    max-width: 100%;
    height: 250px;
}

.banner .bannerLt {
    text-align: left;
    width: 60%;
    background: url('../../../assets/images/triangles.png');
    background-size: 100%;
    background-position: left;
    padding: 35px;
}

.banner .bannerLt h1 {
    font-size: 34px;
    font-weight: 800;
    margin-bottom: 20px;
    color: #384967;
}

.banner .bannerLt h5 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 30px;
    color: #718096;
    line-height: 26px;
}

.banner .bannerLt .primButton {
    width: max-content;
}

.banner .bannerRt {
    width: 40%;
    clip-path: polygon(29% 0, 100% 0, 100% 100%, 0% 100%);
}

.banner .bannerRt img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

@media (max-width: 992px) {
    .banner::after {
        top: 40%;
        font-size: 20px;
    }
}

@media (max-width: 767px) {
    .banner {
        flex-direction: column-reverse;
        height: auto;
    }

    .banner::after {
        left: 15%;
        font-size: 20px;
    }

    .banner .bannerLt {
        width: 100%;
        padding: 25px;
    }

    .banner .bannerRt {
        width: 100%;
        clip-path: none;
        height: 200px;
    }

    .banner .bannerRt img {
        object-position: top;
    }
}

@media (max-width: 500px) {
    .banner .bannerLt .primButton {
        width: 100%;
    }

    .banner .bannerLt h1 {
        font-size: 26px;
        margin-bottom: 10px;
    }

    .banner .bannerLt h5 {
        font-size: 14px;
        margin-bottom: 20px;
    }

    .banner::after {
        left: 15%;
        font-size: 16px;
    }
}

@media (max-width: 445px) {
    .banner .bannerRt {
        height: 150px;
    }

    .banner::after {
        font-size: 12px;
    }

    .banner .bannerLt {
        padding: 15px;
    }
}
