.image-wrapper {
    background: url('../../assets/images/img2.png');
    width: 100%;
    height: 100%;
    min-height: 730px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    z-index: 0;
    display: flex;
}
.image-wrapper::after {
    content: '';
    width: 100%;
    background-color: #d02d2d3b;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    clip-path: circle(100% at 50% 0);
    z-index: -1;
}
.new-image {
    background: url('../../assets/images/teams.png');
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
}
.auctions .searchLt,
.auctions .searchNav {
    display: none;
}
.auctions .np-pro-box {
    margin-bottom: 0px;
    border: none;
    background-color: transparent;
    box-shadow: none;
}
.divider::after {
    content: '';
    width: 100%;
    height: 5px;
    background: currentColor;
    display: block;
    margin-top: -5px;
}
.shape {
    margin-top: 10%;
}
.content {
    margin: auto;
}
.wholesale {
    font-weight: 500;
    font-size: 60px;
}
.lead {
    margin-top: 40px;
}
.readmore {
    background-color: #d02d2d !important;
    padding: 8px 50px !important;
    border-radius: 30px !important;
    border: 1px solid #ef4d47 !important;
}
.pb-15 {
    padding-bottom: 5rem;
}
.mb-13 {
    margin-bottom: 4rem;
}
.text-lg-start {
    text-align: left !important;
}
.py-15 {
    padding-top: 5rem;
    padding-bottom: 5rem;
}
.py-16 {
    padding-top: 6rem;
    padding-bottom: 6rem;
}
.m2-n21 {
    margin-top: 12.5rem;
}
.mt-md-n23 {
    margin-top: -17.5rem !important;
}
.pb-md-17 {
    padding-bottom: 7rem;
}
.py-md-18 {
    padding-top: 8rem;
    padding-bottom: 8rem;
}
.py-md-17 {
    padding-top: 7rem;
}
.pb-md-19 {
    padding-bottom: 9rem;
}
.four {
    border-radius: 50%;
    width: 200px;
    height: 200px;
}
.four .card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.mb-md-17 {
    margin-bottom: 7rem;
}
.mt-n19 {
    margin-top: -9rem;
}
.mt-n17 {
    margin-top: -7rem;
}
.mb-14 {
    margin-bottom: 4.5rem;
}
.four p {
    font-size: 14px;
    font-weight: 500;
    color: #000;
}
.four .material-icons {
    font-size: 30px !important;
    margin-bottom: 15px;
    color: #ef4d49;
}
.computers .inner-container {
    max-width: 1200px;
    margin: 0 auto;
}
.comp_parts {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
}
.world {
    font-size: 12px;
}
.part .material-icons {
    color: #ef4d49;
    margin-bottom: 15px;
}
.bg-pink {
    background-color: #f7f3f5;
}
.part h3 {
    font-size: 22px !important;
}
.part p {
    margin-top: 15px;
}
