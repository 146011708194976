.myAccount {
    background: #f2f2f2;
}

.myAccount .maHeading {
    color: #666;
    margin: 20px auto 25px;
    font-size: 24px;
    text-align: center;
}

.myAccountCnt {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
    padding: 20px 60px;
    width: 100%;
    margin: 20px auto 30px;
    border-radius: 5px;
    background: #fff;
}

.myAccount .customSelect .MuiOutlinedInput-adornedStart {
    padding-left: 0;
}

.myAccount .addrActBtn .primButton,
.myAccount .addrActBtn .secButton {
    margin: 0;
    min-width: 150px;
}

.myAccount .addrActBtn .primButton {
    margin-left: 15px;
}

.myAccountCnt .maTitle {
    font-size: 18px;
    color: var(--primColor);
    font-weight: 700;
    text-align: center;
    margin: 25px auto 35px;
    text-transform: uppercase;
}

.myAccountCnt ul {
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: left;
}

.myAccountCnt ul li label {
    display: block;
    width: 100%;
    font-size: 15px;
    color: #666;
    font-weight: 600;
    margin-bottom: 5px;
}

.myAccountCnt ul li:not(:last-child) {
    margin-bottom: 20px;
}

.myAccountCnt ul li button {
    min-width: 40px;
    margin-left: 10px;
}

.myAccountCnt ul li button .MuiButton-label {
    color: var(--primColor);
}

.myAccountCnt ul li button .MuiButton-label .material-icons {
    font-size: 21px;
}

.myAccount .maAddress {
    text-align: left;
}

.myAccount .maAddress h5 {
    font-size: 16px;
    color: #666;
    font-weight: 600;
}

.myAccount .maAddress address {
    font-size: 16px;
    color: #666;
    font-weight: 400;
}

.myAccount .maNotifications table thead th {
    background: var(--primColor);
    border: none;
    color: #fff;
}

.myAccount .maNotifications .table td,
.myAccount .maNotifications .table th {
    text-align: left;
    vertical-align: middle;
}

/* .addressForm {
    padding: 0px 10%;
} */

.myAccount .phoneEdit .customSelect {
    margin-bottom: 0;
}

.myAccount .material-icons {
    color: var(--primColor);
}

@media (max-width: 500px) {
    .myAccountCnt {
        padding: 20px;
    }
    .myAccount .maNotifications .table td,
    .myAccount .maNotifications .table th {
        font-size: 14px;
    }
    .myAccountCnt .maTitle {
        text-align: left;
    }
    .myAccount .primButton {
        max-width: none;
        width: 100%;
    }
    .myAccountCnt .maTitle {
        font-size: 16px;
        margin: 15px auto 20px;
    }
    .myAccount .maHeading {
        margin: 15px auto 15px;
        font-size: 18px;
    }
    .myAccount .maLang .MuiButton-root {
        float: left;
    }

    .myAccount .maAddress address {
        margin-bottom: 0;
    }

    .myAccount .maTitle {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .myAccount .phoneEdit {
        justify-content: space-between;
    }
}
