.errorPage {
    text-align: center;
    margin: 50px auto;
    padding: 15px 5px 10px 5px;
    color: #333;
}

.errorPage .material-icons {
    color: var(--primColor);
    font-size: 100px;
}

.errorPage h2 {
    font-size: 35px;
    font-weight: 600;
    color: #444;
    margin-top: 20px;
}

.errorPage h5 {
    font-size: 20px;
    margin: 30px 0;
}

.errorPage h5 a {
    font-style: italic;
}
