header .headLt ul,
header .headRt ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

/* header {
    padding: 10px 0;
    background: #ffffff;
    border-bottom: 1px solid #dadada;
} */

header .headLt img {
    max-width: 150px;
    width: 100%;
    height: auto;
}

.loginn {
    border: 1px solid #fe0100 !important;
    padding: 6px 15px !important;
    border-radius: 5px;
}

.AuthHeader .navbar-nav li .loginn a {
    color: #fe0100 !important;
}

/* header .headRt ul li:first-child,
header .headRt ul li:nth-child(2) {
    margin-right: 25px;
} */
.AuthHeader .navbar-nav li a {
    color: #000 !important;
}

.AuthHeader .langButton {
    color: #000 !important;
}

header .headLt ul li {
    margin-left: 25px;
}

header ul li.headCart {
    margin-right: 15px;
}

header ul li.headCart button {
    min-width: initial;
    width: 45px;
    height: 45px;
    border-radius: 50%;
}

header .headRt ul li button.MuiButton-text .MuiButton-label {
    color: #333;
    font-size: 14px;
    font-weight: 600;
    text-transform: none;
}

header .headRt ul li button.MuiButton-text .MuiButton-label:hover {
    color: #000;
}

header .headRt ul li button.MuiButton-text .MuiButton-label:hover > span,
header .headRt ul li button.MuiButton-text .MuiButton-label:hover > span .material-icons {
    color: #000;
}

header .headLt ul li a {
    display: flex;
    align-items: center;
}

header .headLt ul li a .material-icons {
    padding-right: 5px;
}
.German header .headLt ul li a,
.German header .headRt ul li a {
    font-size: 12px;
}

header .headLt ul li a,
header .headRt ul li a {
    color: #333 !important;
    font-size: 14px;
    white-space: nowrap;
    margin-right: 10px;
    font-weight: 600;
    text-decoration: none;
    -webkit-transition: 200ms color ease-in-out;
    -o-transition: 200ms color ease-in-out;
    transition: 200ms color ease-in-out;
    position: relative;
}

header .headRt ul li .divider {
    color: #333;
    padding: 0 5px;
}

header .headLt ul li a:hover,
header .headRt ul li a:hover {
    color: #ca2d0f !important;
}

header .headRt ul li span.material-icons {
    color: #808080;
    font-size: 30px;
}

.mainHeader {
    margin: 10px 0px;
}

header ul li a.active {
    color: var(--primColor) !important;
}

.respNav,
.respNavBtn {
    display: none !important;
}

.searchSlide {
    padding-top: 20px;
}

.searchSlide h3 {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 20px;
}

.searchSlide h3 span {
    margin-left: 10px;
    font-size: 15px;
    text-transform: capitalize;
    color: #808080;
    font-weight: 600;
}

.searchSlide .searchHeaderInput {
    height: 50px;
}

.searchSlide .searchHeaderInput input {
    height: inherit;
}

.searchSlide .searchHeaderInput input:focus {
    box-shadow: none;
    border-color: var(--primColor);
}

.searchSlide .searchHeaderInput .input-group-prepend {
    position: absolute;
    right: 1px;
    top: 1px;
    height: calc(100% - 2px);
    z-index: 100;
    background: #fff;
    color: var(--primColor);
    padding: 0;
    border: none;
}

.searchSlide .searchHeaderInput .input-group-prepend .MuiButton-label {
    height: 100%;
}

.searchSlide .searchHeaderInput .input-group-prepend .input-group-text {
    height: 100%;
    width: 100%;
    border-radius: 2px;
    border: none;
    display: flex;
    justify-content: center;
    background: #fff;
    color: var(--primColor);
}

.popularCatg .catgType label h4 {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 2px;
    width: 100%;
    color: #313131;
    text-align: center;
    margin-top: 10px;
    line-height: 22px;
}

.popularCatg .catgType .iconCnt {
    background: #efefef;
    padding: 10px;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popularCatg .catgType label span {
    font-size: 32px;
    color: #848484;
}

.popularCatg .catgType label .MuiListItem-button {
    height: 100%;
    flex-wrap: wrap;
}

.searchSlide h4 {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 20px;
    color: #717171;
    margin-top: 30px;
}

.popularCatg .catgType {
    width: 33%;
    height: 90px;
    margin-bottom: 15px;
}

.popularCatg .catgType label {
    background: transparent;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    height: 100%;
    border: 1px dashed #777;
}

.popularCatg .catgType input:checked ~ label {
    background: var(--primColor);
    color: #fff;
    border: 1px dashed var(--primColor);
}

.popularCatg .catgType input:checked ~ label h4 {
    color: #fff;
}

.loginn {
    border: 1px solid #ca2d0f !important;
    padding: 6px 15px !important;
    border-radius: 5px;
}

.loginn .MuiButton-label a {
    color: #ca2d0f !important;
    margin: 0px;
}

.popularCatg .catgType input:checked ~ label span {
    color: #ffc6bb;
}

.popularCatg .catgType input:checked ~ label .iconCnt {
    background: #ca2d0f;
}

.popularCatg .catgType label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-right: 10px;
    -webkit-transition: 250ms all ease-in-out;
    -o-transition: 250ms all ease-in-out;
    transition: 250ms all ease-in-out;
}

.logo img {
    max-width: 150px;
    height: auto;
}

.unAuthHeader {
    padding-top: 30px;
    position: absolute;
    width: 100%;
    max-width: 100%;
    padding-right: 2%;
}

.notificationMenu ul {
    padding-top: 0;
    padding-bottom: 0;
    background: #f2f2f2;
    max-width: 250px;
    padding-right: 0 !important;
}

.notificationMenu ul h4 {
    font-size: 15px;
    color: #fff;
    background: #212121;
    height: 40px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.notificationMenu ul .MuiButtonBase-root.MuiListItem-root {
    flex-wrap: wrap;
}

.notificationMenu ul h6 {
    width: 100%;
    font-size: 14px;
    color: #000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
    margin-bottom: 5px;
}

.notificationMenu ul button {
    color: #fff;
    background: var(--primColor);
    width: 100%;
}

.notificationMenu ul a {
    text-decoration: none;
    display: block;
    width: 100%;
    font-size: 14px;
}

.notificationMenu ul a.material-icons {
    font-size: 10px;
}

.notificationMenu ul button:hover {
    background: var(--primColor);
}

.notificationMenu .MuiPaper-root.MuiMenu-paper.MuiPopover-paper {
    top: 60px !important;
    right: 20px !important;
    left: unset !important;
    width: 200px !important;
}

.notificationMenu .noNotification {
    padding: 10px;
    font-size: 15px;
    margin: 0;
    line-height: 20px;
}

.mobHeader ul,
.headerDrawer ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.headerDrawer ul {
    padding: 20px;
}

.headerDrawer ul .MuiButtonBase-root.MuiListItem-root {
    padding: 0;
}

.headerDrawer .searchNav ul .MuiButtonBase-root.MuiListItem-root {
    padding: 10px 0;
    text-transform: initial;
    border: none;
}

.headerDrawer ul .MuiButtonBase-root.MuiListItem-root a {
    padding: 10px 0;
    color: #212121 !important;
    width: 100%;
    display: block;
    text-decoration: none;
}

.headerDrawer .headRtNav {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    height: calc(100vh - 105px);
    /* height: auto; */
}

.headerDrawer .headRtLang .MuiButtonBase-root.MuiButton-root {
    padding: 10px 0px;
    padding-top: 20px;
    width: 100%;
    justify-content: space-between;
}

.headerSearch .MuiPaper-root.MuiDrawer-paper {
    padding: 15px;
}

.headerSearch .customInput {
    margin-bottom: 0;
}

.headerSearch .primButton button {
    min-width: max-content;
}

.brandLogo {
    width: 65px;
}

/* RESPONSIVE */

@media (max-width: 1280px) {
    header ul li.headCart button {
        width: 20px;
        height: 20px;
    }

    header .headRt ul li span.material-icons {
        font-size: 22px;
    }

    header .headLt ul li {
        margin-left: 6px;
    }

    header .headLt ul li,
    header .headRt ul li {
        padding-left: 3px;
        padding-right: 3px;
    }
}

@media (max-width: 991px) {
    .headerDrawer .headRtNav .searchNav {
        display: block;
        box-shadow: none;
    }

    .headerDrawer .headRtNav .searchNav ul {
        flex-wrap: wrap;
        padding: 0;
        margin: 0;
    }

    .headerDrawer .headRtNav .searchNav ul > li {
        padding: 0 0 15px 0;
        text-transform: initial;
        border: none;
    }

    .headRt.respNav .MuiListItem-root > div {
        box-shadow: none;
    }

    .headRt.respNav .MuiListItem-root {
        width: 100%;
    }

    .headerDrawer .searchBox {
        display: none;
    }

    .headerDrawer .headRtNav .searchNav .customContainer {
        padding: 0;
    }

    .searchNav ul .MuiListItem-root {
        font-size: 16px !important;
    }

    .deskNav {
        display: none !important;
    }

    .respNav {
        display: block !important;
    }

    .respNavBtn {
        display: inline-flex !important;
        min-width: max-content !important;
        margin-left: 10px !important;
        color: #000 !important;
    }

    .headRt.respNav .navRespLinks {
        padding-left: 0;
        list-style-type: none;
        flex-wrap: wrap;
    }

    .headRt.respNav .navRespLinks a {
        width: 100%;
        text-decoration: none;
    }

    .headRt.respNav .MuiListItem-root {
        padding: 12px 20px;
        color: #000;
        justify-content: space-between;
    }

    .headRt.respNav .MuiListItem-root > div {
        display: flex;
        align-items: center;
    }

    .headRt.respNav .MuiListItem-root span.material-icons {
        padding-right: 10px;
        color: #adadad;
    }

    .headRt.respNav .naLogoHead {
        padding: 20px 0px 20px 30px;
    }

    .headRt.respNav .naLogoHead img {
        max-width: 150px;
        width: 100%;
    }

    .headerDrawer .MuiPaper-root.MuiDrawer-paper {
        width: 300px;
    }

    .headerDrawer .mobShopLocation.MuiListItem-root {
        flex-wrap: wrap;
    }

    .headerDrawer .mobShopLocation.MuiListItem-root p {
        font-size: 14px;
        padding-left: 8px;
        margin: 0;
    }

    .headerDrawer .mobShopLocation.MuiListItem-root .MuiButtonBase-root {
        width: 100%;
        justify-content: space-between;
    }

    .headerDrawer .mobShopCurrLocation {
        padding-bottom: 0 !important;
    }

    .headerDrawer .headerDrawerNavLinks {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-wrap: wrap;
        min-height: calc(100vh - 100px);
        height: auto;
    }

    .headerDrawer .headerDrawerNavLinks hr {
        width: 100%;
    }

    /* .headRt.respNav button {
        justify-content: space-between;
    } */

    .navCategories {
        padding-left: 0;
        margin: 0;
        padding-top: 10px;
    }

    .navCategories .navBack {
        color: #8a8a8a;
    }

    .navCategories .MuiListItem-button {
        width: 100%;
    }

    .notLoggedLink {
        padding: 10px 20px;
        width: 100%;
    }

    header {
        position: sticky;
        top: 0;
        background: #fff;
        z-index: 100;
        border-bottom: none;
        box-shadow: 0px 3px 10px #cccccc61;
    }
}

@media (max-width: 767px) {
    header .headLt img {
        max-width: 100px;
        width: 100%;
        height: auto;
        object-fit: cover;
    }
}

@media (max-width: 600px) {
    .unAuthHeader {
        padding-top: 15px;
        padding-bottom: 15px;
    }
}

@media (max-width: 500px) {
    .searchSlide h3,
    .searchSlide h4 {
        font-size: 16px;
    }

    .searchSlide h3 span {
        margin-left: 10px;
        font-size: 13px;
    }

    .popularCatg .catgType label h4 {
        font-size: 14px;
    }
}

/* ******* */
