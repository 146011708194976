.mpTitle {
    color: #666;
    margin: 40px auto;
    font-size: 24px;
    text-align: center;
}

.rgTitle {
    color: #666;
    font-size: 18px;
    text-align: center;
    margin: 20px auto;
    width: 100%;
}

.allButtons {
    text-align: center;
    margin: 10px auto;
}
.prefer .regCategories .categorySelect {
    width: 200px;
    height: 100px;
}
.selectButton {
    margin-left: 5px;
}

.prefActBtn {
    margin: 10px auto 45px;
}

.prefActBtn .primButton {
    margin-left: 15px;
}

@media (max-width: 767px) {
    .mpTitle {
        margin: 25px auto 15px;
        font-size: 22px;
    }
}

@media (max-width: 500px) {
    .mpTitle {
        margin: 15px auto 5px;
        font-size: 18px;
    }
    .mpCnt .regCategories {
        margin-bottom: 0 !important;
    }
}
