.navbar {
    width: 100%;
    z-index: 1020;
}
.offcanvas-header {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    flex-direction: row;
}
.offcanvas-header img {
    max-width: 150px;
    /* width: 100%; */
    height: auto;
}
.navbar-nav li a {
    white-space: nowrap;
    color: #fff !important;
}
.langButton {
    color: #fff !important;
    font-size: 16px !important;
}
.loginn {
    border: 1px solid #fff !important;
    padding: 6px 15px !important;
    border-radius: 5px;
}
